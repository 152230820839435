import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../core/Store';
import * as Styles from './Styles';
import CircleButton from '../../../components/buttons/CircleButton';
import { BLUE_200 } from '../../../core/colors';
import SvgLogin from '../../../others/svg_dist/Login';
import FormInput from '../../../components/inputs/FormInput';
import * as AppDataManager from '../../../reducers/data/app';
import * as LoginManager from '../../../reducers/ui/login';

type State = {};

class FormComponent extends React.Component<PropsFromRedux, State> {
	state: State = {};

	render() {
		return (
			<>
				<Styles.ClientFont>Strefa Klienta</Styles.ClientFont>
				<Styles.ClientFont>Mentor S.A.</Styles.ClientFont>
				<Styles.ActionFont>
					Zaloguj się aby rozpocząć pracę.
				</Styles.ActionFont>
				<FormInput
					onChange={(value) => LoginManager.setField(value, 'login')}
					placeholder="login"
					value={this.props.data.login}
					error={this.props.errors.login}
				/>
				<FormInput
					onChange={(value) =>
						LoginManager.setField(value, 'password')
					}
					placeholder="hasło"
					value={this.props.data.password}
					error={this.props.errors.password}
					type="password"
				/>
				<CircleButton
					Icon={<SvgLogin />}
					accent="#fff"
					color={BLUE_200}
					label={`Zaloguj się ${
						this.props.saveTimeout > 0
							? `(${this.props.saveTimeout}s)`
							: ''
					}`}
					onClick={() => AppDataManager.loginApp()}
					variant="solid"
					style={{
						height: '40px',
						margin: '20px auto',
						width: '175px',
					}}
					disabled={!this.props.canSave}
				/>
				<Styles.LeftFoot>
					{/* <Styles.FooterLeft>
							<p>Nie pamiętasz hasła?</p>
							<p>
								Kliknij <a href="/">tutaj.</a>
							</p>
						</Styles.FooterLeft> */}
					<Styles.FooterRight>
						<p>Masz problem z logowaniem?</p>
						<p>
							<span>Zadzwoń: 56 669 33 58</span>
						</p>
					</Styles.FooterRight>
				</Styles.LeftFoot>
			</>
		);
	}
}

interface Own {}

const connector = connect(
	(state: AppState, own: Own) => ({
		data: LoginManager.Selectors.getData(state),
		errors: LoginManager.Selectors.getValidators(state),
		canSave: LoginManager.Selectors.getCanSave(state),
		saveTimeout: LoginManager.Selectors.getSaveTimeout(state),
	}),
	(dispatch) => ({
		setData: (data: LoginManager.Data) =>
			dispatch(LoginManager.Actions.setData(data)),
	})
);

export type PropsFromRedux = ConnectedProps<typeof connector> & Own;

const Form = connector(FormComponent);
export default Form;
