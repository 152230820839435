import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { init } from 'js_helper';
import { Router } from 'react-router';
import { history } from './core/history';
import { Provider } from 'react-redux';
import { store } from './core/Store';
import { ObjectMethods } from 'js_helper';
import moment from 'moment';
import * as LoginManager from './reducers/ui/login';
import * as AppManager from './reducers/ui/app';

Object.assign(window, {
	ObjectMethods,
	store,
	moment,
});

export const loadAppResources = () => {
	setInterval(() => {
		LoginManager.tickSaveTimeout();
	}, 1000);
	AppManager.initViewportWidthTracker();
};

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<>
		<Router history={history}>
			<Provider store={store}>
				<App />
			</Provider>
		</Router>
	</>
);

init();
