import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../core/Store';
import { cleanNumberInput } from '../../../core/helpers';
import * as LoginManager from '../../../reducers/ui/login';
import Button from '../../../components/buttons/Button';
import * as Styles from './Styles';

type State = {};

class TwoFactorComponent extends React.Component<PropsFromRedux, State> {
	state: State = {};

	digit1Ref = React.createRef<HTMLInputElement>();
	digit2Ref = React.createRef<HTMLInputElement>();
	digit3Ref = React.createRef<HTMLInputElement>();
	digit4Ref = React.createRef<HTMLInputElement>();
	digit5Ref = React.createRef<HTMLInputElement>();
	digit6Ref = React.createRef<HTMLInputElement>();

	allRefs = [
		this.digit1Ref,
		this.digit2Ref,
		this.digit3Ref,
		this.digit4Ref,
		this.digit5Ref,
		this.digit6Ref,
	];

	onPaste = (e?: React.ClipboardEvent<HTMLInputElement>) => {
		e?.preventDefault();

		e?.clipboardData.items[0]?.getAsString((item) => {
			if (!parseInt(item) || item.length !== 6) return;

			this.props.setConfirmCodeDigit(0, item[0]);
			this.props.setConfirmCodeDigit(1, item[1]);
			this.props.setConfirmCodeDigit(2, item[2]);
			this.props.setConfirmCodeDigit(3, item[3]);
			this.props.setConfirmCodeDigit(4, item[4]);
			this.props.setConfirmCodeDigit(5, item[5]);
		});
	};

	setDigit = (pos: number, value: string) => {
		this.props.setConfirmCodeDigit(pos, value.charAt(value.length - 1));
		value && this.allRefs[pos + 1]?.current?.focus();

		const check = this.props.confirmCode.map((el, idx) => {
			if (idx === pos) {
				return value;
			}
			return el;
		});

		if (check.every((el) => !!el)) {
			LoginManager.sendConfirm();
			this.allRefs.forEach((ref) => {
				ref.current?.blur();
			});
		}
	};

	componentDidMount(): void {
		this.digit1Ref.current?.focus();
	}

	componentWillUnmount(): void {
		LoginManager.clearCode();
	}

	render() {
		return (
			<>
				<Styles.ConfirmTopFont>
					Autoryzacja dwuetapowa
				</Styles.ConfirmTopFont>
				<Styles.ConfirmBottomFont>
					Aby dokończyć logowanie należy podać kod wysłany na nr.
					telefonu połączony z tym kontem.
				</Styles.ConfirmBottomFont>
				<Styles.PinContainer>
					<input
						type="number"
						onChange={(e) => this.setDigit(0, e.target.value)}
						value={this.props.confirmCode[0]}
						onPaste={(e) => this.onPaste(e)}
						ref={this.digit1Ref}
						onKeyDown={cleanNumberInput}
					/>
					<input
						type="number"
						onChange={(e) => this.setDigit(1, e.target.value)}
						value={this.props.confirmCode[1]}
						onPaste={() => this.onPaste()}
						ref={this.digit2Ref}
						onKeyDown={cleanNumberInput}
					/>
					<input
						type="number"
						onChange={(e) => this.setDigit(2, e.target.value)}
						value={this.props.confirmCode[2]}
						onPaste={() => this.onPaste()}
						ref={this.digit3Ref}
						onKeyDown={cleanNumberInput}
					/>
					<input
						type="number"
						onChange={(e) => this.setDigit(3, e.target.value)}
						value={this.props.confirmCode[3]}
						onPaste={() => this.onPaste()}
						ref={this.digit4Ref}
						onKeyDown={cleanNumberInput}
					/>
					<input
						type="number"
						onChange={(e) => this.setDigit(4, e.target.value)}
						value={this.props.confirmCode[4]}
						onPaste={() => this.onPaste()}
						ref={this.digit5Ref}
						onKeyDown={cleanNumberInput}
					/>
					<input
						type="number"
						onChange={(e) => this.setDigit(5, e.target.value)}
						value={this.props.confirmCode[5]}
						onPaste={() => this.onPaste()}
						ref={this.digit6Ref}
						onKeyDown={cleanNumberInput}
					/>
				</Styles.PinContainer>
				<Button
					accent="#fff"
					color="#1c2c57"
					label="wstecz"
					onClick={() => this.props.setStep('form')}
					variant="solid"
					style={{
						height: '45px',
						width: '250px',
						margin: '0 auto',
						borderRadius: '40px',
					}}
				/>
			</>
		);
	}
}

interface Own {}

const connector = connect(
	(state: AppState, own: Own) => ({
		confirmCode: LoginManager.Selectors.getConfirmCode(state),
	}),
	(dispatch) => ({
		setConfirmCodeDigit: (pos: number, digit: string) =>
			dispatch(
				LoginManager.Actions.setConfirmCodeDigit({
					pos,
					digit,
				})
			),
		setStep: (step: 'form' | 'confirm') =>
			dispatch(LoginManager.Actions.setStep(step)),
	})
);

export type PropsFromRedux = ConnectedProps<typeof connector> & Own;

const TwoFactor = connector(TwoFactorComponent);
export default TwoFactor;
