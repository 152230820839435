import styled from 'styled-components';

export const Wrapper = styled.div<{ stepConfirm: boolean }>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	padding: 50px 20px 100px;
	z-index: 999;
	position: relative;
	& > svg {
		fill: #fff;
		width: 70px;
		height: auto;
	}
	${({ stepConfirm }) =>
		stepConfirm &&
		`
		justify-content: center;
	`}
`;

export const HeadingTopFont = styled.p`
	margin: 0;
	line-height: 1.2;
	color: #fff;
	font-weight: 600;
	font-size: 17px;
	margin-top: 50px;
`;

export const HeadingBottomFont = styled.p`
	margin: 0;
	line-height: 1.4;
	color: #fff;
	font-weight: 600;
	font-size: 28px;
	margin-bottom: 50px;
`;

export const MobileField = styled.input<{ error?: boolean }>`
	all: unset;
	width: 98%;
	margin: 10px auto;
	height: 50px;
	text-align: center;
	background-color: #ffffff14;
	backdrop-filter: blur(5px);
	font-size: 16px;
	border-radius: 5px;
	color: #fff;
	transition: all 100ms linear;
	&:focus {
		background-color: #fff;
		color: #000;
	}
	${({ error }) =>
		error &&
		`
        outline: 2px solid #C42055;
    `}
`;

export const BottomInfoContainer = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	p {
		color: #fff;
		margin: 0;
		text-align: center;
		line-height: 1.5;
		font-size: 13px;
		span {
			font-weight: 600;
		}
	}
`;

export const BottomDivider = styled.div`
	width: 125px;
	height: 1px;
	background-color: #595a5f;
	margin: 15px auto;
`;

export const CopyrightFont = styled.p`
	margin: 30px 0 !important;
	margin-top: 15px !important;
	color: #595a5f !important;
`;

export const ErrorFont = styled.p`
	color: #c42055;
	margin: 10px 0 -10px;
	font-size: 16px;
`;

export const PinContainer = styled.div`
	width: 300px;
	margin: 20px auto;
	margin-bottom: 40px;
	display: flex;
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
		appearance: textfield;
	}

	input {
		all: unset;
		background-color: #ffffff14;
		backdrop-filter: blur(5px);
		flex: 1;
		width: unset;
		min-width: 0;
		height: 50px;
		margin: 0 5px;
		text-align: center;
		font-size: 18px;
		border-radius: 2px;
		color: #fff;
		transition: all 100ms linear;
		&:focus {
			background-color: #fff;
			color: #000;
		}
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@media screen and (max-width: 410px) {
		width: 270px;
	}
`;

export const ConfirmTopFont = styled.p`
	margin: 0;
	font-weight: 600;
	font-size: 25px;
	margin-top: 50px;
	text-align: center;
	color: #fff;
`;

export const ConfirmBottomFont = styled.p`
	text-align: center;
	margin: 0;
	margin-bottom: 10px;
	line-height: 1.3;
	margin-top: 8px;
	color: #fff;
	max-width: 80%;
`;
