import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	overflow-y: auto;
	height: 100%;
`;

export const Container = styled.div`
	display: flex;
	flex-flow: column nowrap;
	margin: auto;
	position: relative;
	height: 100%;
	overflow: hidden;
`;

export const VideoBg = styled.video`
	width: 100%;
	position: absolute;
	object-fit: cover;
	height: 100%;
`;
