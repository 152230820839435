import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../core/Store';
import * as AppDataManager from '../../../reducers/data/app';
import SvgLogoMentor2 from '../../../others/svg_dist/LogoMentor2';
import { BLUE_200 } from '../../../core/colors';
import SvgLogin from '../../../others/svg_dist/Login';
import CircleButton from '../../../components/buttons/CircleButton';
import * as Styles from './Styles';
import * as LoginManager from '../../../reducers/ui/login';

type State = {};

class FormComponent extends React.Component<PropsFromRedux, State> {
	state: State = {};

	render() {
		return (
			<>
				<SvgLogoMentor2 />
				<Styles.HeadingTopFont>Mentor S.A.</Styles.HeadingTopFont>
				<Styles.HeadingBottomFont>
					Strefa Klienta
				</Styles.HeadingBottomFont>
				<Styles.MobileField
					type="text"
					placeholder="login"
					spellCheck={false}
					value={this.props.data.login}
					onChange={(e) =>
						LoginManager.setField(e.target.value, 'login')
					}
					error={!!this.props.errors.login}
				/>
				<Styles.MobileField
					type="password"
					placeholder="hasło"
					spellCheck={false}
					value={this.props.data.password}
					onChange={(e) =>
						LoginManager.setField(e.target.value, 'password')
					}
					error={!!this.props.errors.password}
				/>
				{(this.props.errors.login || this.props.errors.password) && (
					<Styles.ErrorFont>
						niepoprawny login i/lub hasło
					</Styles.ErrorFont>
				)}
				<CircleButton
					Icon={<SvgLogin />}
					accent={BLUE_200}
					color="#fff"
					label={`zaloguj się ${
						this.props.saveTimeout > 0
							? `(${this.props.saveTimeout}s)`
							: ''
					}`}
					onClick={() => AppDataManager.loginApp()}
					variant="solid"
					style={{
						height: '45px',
						width: '180px',
						fontSize: '16px',
						fontWeight: '600',
						marginTop: '40px',
						scale: '1.1',
					}}
					disabled={!this.props.canSave}
				/>
			</>
		);
	}
}

interface Own {}

const connector = connect(
	(state: AppState, own: Own) => ({
		data: LoginManager.Selectors.getData(state),
		errors: LoginManager.Selectors.getValidators(state),
		canSave: LoginManager.Selectors.getCanSave(state),
		saveTimeout: LoginManager.Selectors.getSaveTimeout(state),
	}),
	(dispatch) => ({})
);

export type PropsFromRedux = ConnectedProps<typeof connector> & Own;

const Form = connector(FormComponent);
export default Form;
