import * as React from "react";
import type { SVGProps } from "react";
const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fillRule="evenodd"
    clipRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    viewBox="0 0 2709.32 2709.32"
    {...props}
  >
    <path
      d="m1466.62 2085.3-306.41 6.08v-736.72h-147.3v-253.37h147.3V942.2c0-98.54 29.54-174.78 73.04-233.36 31.88-42.94 138.64-90.71 209.8-90.71h253.37v253.36h-147.31c-41.9 0-82.49 23.66-82.49 64.82v164.98h229.8l-25.62 253.17-204.18.2v730.64zm-100.17 618.69c733.92 0 1337.56-611.47 1337.56-1349.33 0-730.69-596.34-1349.33-1319.89-1349.33h-94.28c-364.63 0-706.61 208.72-891.21 393.32C213.91 583.36 5.31 924.99 5.31 1289.84v82.49c0 742.76 613.56 1331.66 1361.14 1331.66z"
      className="fil0"
    />
  </svg>
);
export default SvgFacebook;
