import * as React from "react";
import type { SVGProps } from "react";
const SvgLogin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fillRule="evenodd"
    clipRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    viewBox="0 0 4233.32 4233.32"
    {...props}
  >
    <path
      fillRule="nonzero"
      d="M616.09 1250.57c-53.12 91.72-170.57 123-262.3 69.88-91.73-53.11-123-170.57-69.89-262.29C472.61 731.43 741.97 466.97 1059 283.95 1376.09 100.93 1739.61-.17 2116.59-.17c584.46 0 1113.69 236.96 1496.73 620.03 383.05 383.07 620.01 912.28 620.01 1496.8s-236.96 1113.73-620.01 1496.8c-383.04 383.07-912.27 620.03-1496.73 620.03-377.5 0-741.32-101.1-1058.36-284.16-315.87-182.39-584.96-447.1-774.33-775.1-52.7-91.73-21.09-208.84 70.64-261.54 91.73-52.71 208.84-21.1 261.55 70.63 154.38 267.41 374.93 483.91 634.54 633.82 258.32 149.16 555.98 231.53 865.96 231.53 478.3 0 911.32-193.87 1224.69-507.26 313.36-313.38 507.23-746.43 507.23-1224.75 0-478.32-193.87-911.37-507.23-1224.75-313.37-313.39-746.39-507.26-1224.69-507.26-310.5 0-608.34 82.37-866.68 231.48-258.48 149.23-478.73 365.94-633.82 634.44zm1027.73 328.57c-75.12-75.12-75.12-196.94 0-272.05 75.11-75.11 196.93-75.11 272.04 0l670.26 670.3c36.75 35.04 59.66 84.5 59.66 139.27 0 54.77-22.91 104.23-59.66 139.27l-670.26 670.3c-75.11 75.11-196.93 75.11-272.04 0-75.12-75.11-75.12-196.93 0-272.05l345.09-345.11H192.4c-106.25 0-192.41-86.16-192.41-192.41s86.16-192.41 192.41-192.41h1796.51l-345.09-345.11z"
      className="fil0"
    />
  </svg>
);
export default SvgLogin;
