import { Actions } from './reducer';
import * as Selectors from './selectors';
import * as LoginManager from '../../ui/login';
import { store } from '../../../core/Store';
import { ToastsManager } from 'toasts';
import * as Api from './api';
import { KwardaErrorTimeout, KwardaLoginError } from '../../../models/Global';

export { Actions, Selectors };

export const loginApp = () => {
	const canLogin = LoginManager.Selectors.getCanSave(store.getState());

	if (!canLogin) {
		ToastsManager.pushToast({
			content: 'Podane dane nie są poprawne',
			alertProps: {
				severity: 'error',
			},
			autoHideDuration: 5000,
		});
		return;
	}

	store.dispatch(Actions.setIsDataLoaded(false));

	const data = LoginManager.Selectors.getData(store.getState());
	const payload: LoginPayload = {
		login: data.login,
		haslo: btoa(data.password),
	};

	Api.login(payload).then((res) => {
		if (res?.status === 'error') {
			ToastsManager.pushToast({
				content: (res as KwardaLoginError)?.bledy
					.map((el) =>
						typeof el === 'object' ? el.error : (el as string)
					)
					.join(),
				alertProps: {
					severity: 'error',
				},
				autoHideDuration: 5000,
			});

			// @ts-ignore
			if (res.bledy.find((el) => typeof el === 'object')) {
				// @ts-ignore
				const err = res.bledy.filter(
					(el: any) => typeof el === 'object'
				) as KwardaErrorTimeout[];

				if (err.length > 1) {
					throw new Error('krzychu zabije cie');
				}

				store.dispatch(
					LoginManager.Actions.setSaveTimeout(parseInt(err[0].czas))
				);
			}

			return;
		}

		Api.getHasTwoFactor().then((hasTwoFactor) => {
			if (hasTwoFactor) {
				store.dispatch(LoginManager.Actions.setStep('confirm'));
				return;
			}
			window.location.replace('/');
		});
	});
};

export interface LoginPayload {
	login: string;
	haslo: string;
}

export interface LoginResponse {
	czas?: string;
	error?: string;
}
