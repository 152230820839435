import thunk from 'redux-thunk';
import {
	combineReducers,
	compose,
	applyMiddleware,
	configureStore,
} from '@reduxjs/toolkit';
import { uiReducer } from '../reducers/uiReducer';
import { dataReducer } from '../reducers/dataReducer';

const middleware = process.env.NODE_ENV !== 'production' ? [thunk] : [thunk];

const rootReducer = combineReducers({
	ui: uiReducer,
	data: dataReducer,
});

const enhancer = compose(applyMiddleware(...middleware));

export const store = configureStore({
	reducer: rootReducer,
	devTools: true,
	enhancers: [enhancer],
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
