import React, { Component } from 'react';
import BaseView from '../../views/baseView/BaseView';
import styled from 'styled-components';

const PageWrapper = styled.div`
	overflow-y: auto;
	width: 100%;
	height: 100vh;
`;

type Props = {};

type State = {};

class BasePage extends Component<Props, State> {
	state = {};

	render() {
		return (
			<PageWrapper>
				<BaseView />
			</PageWrapper>
		);
	}
}

export default BasePage;
