import { AppState } from '../../../core/Store';
import { State } from './reducer';

type Selector<T = any> = (redState: State, appState: AppState) => T;
function prepareSelector<T>(selector: Selector<T>) {
	return (state: AppState) => {
		return selector(state.ui.login, state);
	};
}

export const getData = prepareSelector((redState, appState) => {
	return redState.data;
});

export const getValidators = prepareSelector((redState, appState) => {
	return redState.validators;
});

export const getCanSave = prepareSelector((redState, appState) => {
	return redState.canSave && redState.saveTimeout === 0;
});

export const getSaveTimeout = prepareSelector((redState, appState) => {
	return redState.saveTimeout;
});

export const getStep = prepareSelector((redState, appState) => {
	return redState.step;
});

export const getConfirmCode = prepareSelector((redState, appState) => {
	return redState.confirmCode;
});
