export type PFetchError = {
	status: number;
	statusText: string;
};

export interface ICustomHandler<T> {
	fn: (
		client: XMLHttpRequest,
		resolve: (value: T | PromiseLike<T>) => void,
		reject: (reason: PFetchError) => void
	) => void;
	returnAfter: boolean;
}

export interface IPFetchConfig<T, P> {
	customHandlers?: {
		onLoad?: ICustomHandler<T>;
		onReject?: ICustomHandler<T>;
		onNetworkError?: ICustomHandler<T>;
	};
	async?: boolean;
	body?: {
		content: P;
		type: 'json' | 'formdata';
	};
	method?: 'GET' | 'POST';
}

/**
 * Native XHR Wrapper. After a sucessful request the function will try to parse the response to JSON Object of type generic T, if the resource cannot be parsed, the response body will be returned. The config object argument can be used to change success handlers or request method/body.
 * @param url Resource URL
 * @param config config object, can be used to overwrite handlers
 * @returns fetched resource as generic T if resource type is a valid JSON object | response body if resource type is not a valid JSON object
 * @throws TypeError - on network fail | PFetchError - on request status other than 200
 */
export const pFetch = <T = any, P = any>(
	url: string,
	config?: IPFetchConfig<T, P>
) => {
	return new Promise<T>((resolve, reject) => {
		const client = new XMLHttpRequest();
		let body: any = null;

		if (config?.body?.type === 'json' && config.body.content) {
			body = JSON.stringify(config.body.content);
		}

		if (config?.body?.type === 'formdata' && config.body.content) {
			body = config.body.content;
		}

		client.open(config?.method || 'GET', url, config?.async || true);
		client.send(body);

		client.onload = () => {
			config?.customHandlers?.onLoad?.fn(client, resolve, reject);
			if (config?.customHandlers?.onLoad?.returnAfter) return;

			if (client.status === 200) {
				const responseType = client.getResponseHeader('Content-Type');

				if (responseType?.includes('application/json')) {
					resolve(JSON.parse(client.response) as T);
				}

				resolve(client.response as T);
				return;
			}

			if (client.status === 0) {
				config?.customHandlers?.onNetworkError?.fn(
					client,
					resolve,
					reject
				);
				if (config?.customHandlers?.onNetworkError?.returnAfter) return;

				throw new TypeError('Network error');
			}

			if (client.status !== 200) {
				config?.customHandlers?.onReject?.fn(client, resolve, reject);
				if (config?.customHandlers?.onReject?.returnAfter) return;

				reject({
					status: client.status,
					statusText: client.statusText,
				});
				return;
			}
		};

		client.onerror = () => {
			config?.customHandlers?.onNetworkError?.fn(client, resolve, reject);
			if (config?.customHandlers?.onNetworkError?.returnAfter) return;

			throw new TypeError('Network error');
		};
	});
};
