import React, { CSSProperties, Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{
	accent: string;
	color: string;
	transparent: boolean;
	disabled?: boolean;
}>`
	height: 32px;
	display: flex;
	align-items: center;
	border: 1px solid ${({ color }) => color};
	color: ${({ accent }) => accent};
	background-color: ${({ color, transparent }) =>
		transparent ? 'transparent' : color};
	cursor: pointer;
	user-select: none;
	overflow: hidden;
	p {
		height: 100%;
		width: 100%;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 8px;
	}
	&:hover {
		${({ disabled, accent, color, transparent }) =>
			!disabled &&
			`
			p {
				color: ${color};
				background-color: ${accent};
			}
		`}
	}
	${({ disabled }) =>
		disabled &&
		`
		opacity: 50%;
		cursor: not-allowed;
	`}
`;

type Props = {
	accent: string;
	color: string;
	disabled?: boolean;
	label: string;
	onClick: (e: React.MouseEvent<HTMLDivElement, any>) => void;
	variant: 'solid' | 'transparent';
	style?: CSSProperties;
};

type State = {};

class Button extends Component<Props, State> {
	state = {};

	render() {
		return (
			<Wrapper
				accent={this.props.accent}
				color={this.props.color}
				disabled={this.props.disabled}
				onClick={this.props.disabled ? undefined : this.props.onClick}
				transparent={this.props.variant === 'transparent'}
				style={this.props.style}
			>
				<p>{this.props.label}</p>
			</Wrapper>
		);
	}
}

export default Button;
