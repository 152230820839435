import { Actions, Selectors, Validators } from '.';
import { store } from '../../../core/Store';
import { initialState } from './reducer';

export type ValidateKey = 'login' | 'password';

export type ValidateMethod = 'login' | 'password';

const updateValidators = (validators: Validators) => {
	store.dispatch(Actions.setValidators(validators));
};

const updateValidatorsField = (field: ValidateMethod, isIncorrect: boolean) => {
	const validators = Selectors.getValidators(store.getState());
	updateValidators({
		...validators,
		[field]: isIncorrect,
	});
};

// podstawowa metoda walidacji
export const simpleFieldValidate = (
	field: ValidateMethod,
	value: any,
	type?: 'string' | 'number' | 'array',
	update: boolean = true
) => {
	let isIncorrect = false;
	if (type) {
		switch (type) {
			case 'string':
				if (!(typeof value === 'string' && value.length)) {
					isIncorrect = true;
				}
				break;
			case 'number':
				if (typeof value !== 'number') {
					isIncorrect = true;
				}
				break;
			case 'array':
				if (!(value && value.length)) {
					isIncorrect = true;
				}
				break;
			default:
				break;
		}
	} else {
		// tu można rozpisać wyjątki walidacji dla poszczególnych pól
		switch (field) {
			case 'login':
				if (value.length < 3) {
					isIncorrect = true;
				}
				break;
			case 'password':
				if (value.length < 3) {
					isIncorrect = true;
				}
				break;
			default:
				break;
		}
	}

	if (update) {
		updateValidatorsField(field, isIncorrect);
	}

	return isIncorrect;
};

/**
 * Walidacja całości formularza przez zapisem + w celu sprawdzania czy przycisk zapisu jest disabled
 * @param updateValidators - aktualizuj validatory
 * @param showWarnings - pokaż warningi
 * @returns boolean, true - można zapisywac, false - coś jest nie tak
 */
export const validateBeforeSave = (
	updateValidators: boolean = false,
	showWarnings: boolean = false
) => {
	let isOk = true;
	const state = store.getState();
	const data = Selectors.getData(state);

	const validators: Validators = {
		...initialState.validators,
	};

	if (simpleFieldValidate('login', data.login)) {
		validators.login = true;
		isOk = false;
	} else {
		validators.login = false;
	}

	if (simpleFieldValidate('password', data.password)) {
		validators.password = true;
		isOk = false;
	} else {
		validators.password = false;
	}

	store.dispatch(Actions.setCanSave(isOk));

	return isOk;
};
