import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../core/Store';
import * as Styles from './Styles';
import * as LoginManager from '../../../reducers/ui/login';
import Form from './Form';
import TwoFactor from './TwoFactor';

type State = {};

class MobileComponent extends React.Component<PropsFromRedux, State> {
	state: State = {};

	render() {
		return (
			<Styles.Wrapper stepConfirm={this.props.step === 'confirm'}>
				{this.props.step === 'form' ? <Form /> : <TwoFactor />}
				<Styles.BottomInfoContainer>
					<Styles.BottomDivider />
					<p>Wystąpił problem z logowaniem?</p>
					<p>
						Zadzwoń: <span>56 669 33 58</span>
					</p>
					<Styles.BottomDivider />
					<Styles.CopyrightFont>
						© {new Date().getFullYear()} Mentor S.A. // All Rights
						Reserved
					</Styles.CopyrightFont>
				</Styles.BottomInfoContainer>
			</Styles.Wrapper>
		);
	}
}

interface Own {}

const connector = connect(
	(state: AppState, own: Own) => ({
		step: LoginManager.Selectors.getStep(state),
	}),
	(dispatch) => ({})
);

export type PropsFromRedux = ConnectedProps<typeof connector> & Own;

const Mobile = connector(MobileComponent);
export default Mobile;
