import { createSlice } from '@reduxjs/toolkit';
import * as Seters from './seters';

export interface State {
	isDataLoaded: boolean;
	twoFactorEnabled: boolean;
}

export const initialState: State = {
	isDataLoaded: false,
	twoFactorEnabled: true,
};

export const slice = createSlice({
	name: 'data/app',
	initialState,
	reducers: Seters,
});

export const appReducer = slice.reducer;
const Actions = slice.actions;
export { Actions };
