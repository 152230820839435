import { Data, Validators } from '.';
import { State } from './reducer';
import { PayloadAction } from '@reduxjs/toolkit';

export const setData = (state: State, action: PayloadAction<Data>) => {
	state.data = action.payload;
};

export const setValidators = (
	state: State,
	action: PayloadAction<Validators>
) => {
	state.validators = action.payload;
};

export const setCanSave = (state: State, action: PayloadAction<boolean>) => {
	state.canSave = action.payload;
};

export const setSaveTimeout = (state: State, action: PayloadAction<number>) => {
	state.saveTimeout = action.payload;
};

export const setStep = (state: State, action: PayloadAction<State['step']>) => {
	state.step = action.payload;
};

export const setConfirmCodeDigit = (
	state: State,
	action: PayloadAction<{ pos: number; digit: string }>
) => {
	state.confirmCode[action.payload.pos] = action.payload.digit;
};
