import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Routing } from '../core/Routing';
import BasePage from './Base/BasePage';

type Props = {};

type State = {};

class LoginRouting extends Component<Props, State> {
	state = {};

	render() {
		return (
			<>
				<Switch>
					<Route path={Routing.main.base}>
						<BasePage />
					</Route>
					<Redirect from="*" to={Routing.main.base} />
				</Switch>
			</>
		);
	}
}

export default LoginRouting;
