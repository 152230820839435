import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 20px;
	border: 2px solid #eecb33;
	border-left: none;
	padding-left: 22px;
	position: absolute;
	right: -90px;
	font-size: 16px;
	p {
		margin: 0;
		color: #fff;
		line-height: 1.2;
		text-align: center;
	}
`;

const HourFont = styled.p`
	@keyframes Tick {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
	font-size: 36px;
	font-weight: 600;
	span {
		animation-name: Tick;
		animation-duration: 2000ms;
		animation-iteration-count: infinite;
		animation-timing-function: steps(2);
	}
`;

const LeftBorder = styled.div`
	position: absolute;
	width: 2px;
	background-color: #eecb33;
	height: 75%;
	top: 0;
	left: 0;
`;

type Props = {};

type State = {
	displayTime: {
		hours: string;
		minutes: string;
	};
};

class Clock extends Component<Props, State> {
	state: State = {
		displayTime: {
			hours: moment().format('HH'),
			minutes: moment().format('mm'),
		},
	};

	componentDidMount(): void {
		// nie trzeba czyścić, zawsze załadowane
		setInterval(() => {
			this.setState({
				...this.state,
				displayTime: {
					hours: moment().format('HH'),
					minutes: moment().format('mm'),
				},
			});
		}, 1000);
	}

	getDisplayMonthName = (month: number) => {
		switch (month) {
			case 0:
				return 'st';
			case 1:
				return 'lut';
			case 2:
				return 'mrz';
			case 3:
				return 'kw';
			case 4:
				return 'maj';
			case 5:
				return 'cz';
			case 6:
				return 'lip';
			case 7:
				return 'sier';
			case 8:
				return 'wrz';
			case 9:
				return 'paź';
			case 10:
				return 'lis';
			case 11:
				return 'gr';
			default:
				throw new Error('data poza granicą');
		}
	};

	getDisplayDayName = (day: number) => {
		switch (day) {
			case 1:
				return 'Poniedziałek';
			case 2:
				return 'Wtorek';
			case 3:
				return 'Środa';
			case 4:
				return 'Czwartek';
			case 5:
				return 'Piątek';
			case 6:
				return 'Sobota';
			case 0:
				return 'Niedziela';
			default:
				throw new Error('data poza granicą');
		}
	};

	currentDay = moment().format('D');
	currentMonth = this.getDisplayMonthName(moment().month());
	currentYear = moment().year();
	currentDisplayDay = this.getDisplayDayName(moment().weekday());

	render() {
		return (
			<Wrapper>
				<p>
					{this.currentDay} {this.currentMonth} {this.currentYear}
				</p>
				<HourFont>
					{this.state.displayTime.hours}
					<span>:</span>
					{this.state.displayTime.minutes}
				</HourFont>
				<p>{this.currentDisplayDay}</p>
				<LeftBorder />
			</Wrapper>
		);
	}
}

export default Clock;
