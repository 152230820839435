import React, { Component } from 'react';
import * as Styles from './Styles';
import { ConnectedProps, connect } from 'react-redux';
import { AppState } from '../../../core/Store';
import SvgWww from '../../../others/svg_dist/Www';
import SvgFacebook from '../../../others/svg_dist/Facebook';
import SvgLinkedin from '../../../others/svg_dist/Linkedin';
import SvgLogoMentor2 from '../../../others/svg_dist/LogoMentor2';
import SvgRobotMentor from '../../../others/svg_dist/RobotMentor';
import Clock from './Clock';
import * as LoginManager from '../../../reducers/ui/login';
import * as AppDataManager from '../../../reducers/data/app';
import Form from './Form';
import TwoFactor from './TwoFactor';

type State = {};

class PanelComponent extends Component<PropsFromRedux, State> {
	state = {};

	// komponent jest załadowany zawsze, nie ma potrzeby usuwać listinera
	componentDidMount(): void {
		window.addEventListener('keypress', (e) => {
			e.code === 'Enter' && AppDataManager.loginApp();
		});
	}

	render() {
		return (
			<Styles.Wrapper>
				<Styles.LeftSection>
					{this.props.step === 'form' ? <Form /> : <TwoFactor />}
				</Styles.LeftSection>
				<Styles.RightSection>
					<Styles.LogoContainer>
						<SvgLogoMentor2 />
					</Styles.LogoContainer>
					<Clock />
					<Styles.DecorContainer>
						<SvgRobotMentor />
					</Styles.DecorContainer>
					<Styles.RightFoot>
						<a
							href="https://www.mentor.pl/"
							target="_blank"
							rel="noreferrer noopener"
						>
							<SvgWww />
						</a>
						<a
							href="https://www.facebook.com/mentor.broker/"
							target="_blank"
							rel="noreferrer noopener"
						>
							<SvgFacebook />
						</a>
						<a
							href="https://pl.linkedin.com/company/mentors.a."
							target="_blank"
							rel="noreferrer noopener"
						>
							<SvgLinkedin />
						</a>
					</Styles.RightFoot>
				</Styles.RightSection>
			</Styles.Wrapper>
		);
	}
}

interface Own {}

const connector = connect(
	(state: AppState, own: Own) => ({
		step: LoginManager.Selectors.getStep(state),
	}),
	(dispatch) => ({})
);

export type PropsFromRedux = ConnectedProps<typeof connector> & Own;

const Panel = connector(PanelComponent);
export default Panel;
