import { createSlice } from '@reduxjs/toolkit';
import * as Seters from './seters';
import { Data, Validators } from '.';

export interface State {
	data: Data;
	validators: Validators;
	isDataLoaded: boolean;
	canSave: boolean;
	saveTimeout: number;
	step: 'form' | 'confirm';
	confirmCode: string[];
}

export const defaultData: Data = {
	login: '',
	password: '',
};

export const defaulValidators: Validators = {
	login: false,
	password: false,
};

export const initialState: State = {
	data: defaultData,
	validators: defaulValidators,
	isDataLoaded: false,
	canSave: false,
	saveTimeout: 0,
	step: 'form',
	confirmCode: ['', '', '', '', '', ''],
};

export const slice = createSlice({
	name: 'ui/login',
	initialState,
	reducers: Seters,
});

export const loginReducer = slice.reducer;
const Actions = slice.actions;
export { Actions };
