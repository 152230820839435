import { State } from './reducer';
import { PayloadAction } from '@reduxjs/toolkit';

export const setIsDataLoaded = (
	state: State,
	action: PayloadAction<boolean>
) => {
	state.isDataLoaded = action.payload;
};

export const setTwoFactorEnabled = (
	state: State,
	action: PayloadAction<boolean>
) => {
	state.twoFactorEnabled = action.payload;
};
