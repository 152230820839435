import { store } from '../../../core/Store';
import { Actions, defaultData } from './reducer';
import * as Selectors from './selectors';
import * as Validate from './validators';
import * as Api from './api';
import { throwErrorWithDefaultTemplate } from '../../../core/helpers';

export { Actions, Selectors, Validate };

export const setField = (value: string, key: keyof Data) => {
	const currentData = Selectors.getData(store.getState());

	if (currentData[key] === value) return;

	store.dispatch(
		Actions.setData({
			...currentData,
			[key]: value,
		})
	);

	Validate.simpleFieldValidate(key, value);
	Validate.validateBeforeSave();
};

export const clearCode = () => {
	store.dispatch(Actions.setConfirmCodeDigit({ pos: 0, digit: '' }));
	store.dispatch(Actions.setConfirmCodeDigit({ pos: 1, digit: '' }));
	store.dispatch(Actions.setConfirmCodeDigit({ pos: 2, digit: '' }));
	store.dispatch(Actions.setConfirmCodeDigit({ pos: 3, digit: '' }));
	store.dispatch(Actions.setConfirmCodeDigit({ pos: 4, digit: '' }));
	store.dispatch(Actions.setConfirmCodeDigit({ pos: 5, digit: '' }));
};

export const sendConfirm = () => {
	const confirmCode = Selectors.getConfirmCode(store.getState());

	Api.sendConfirm({ kod: confirmCode.join('') }).then((res) => {
		if (res?.status === 'error') {
			throwErrorWithDefaultTemplate('Podany kod nie jest poprawny', {
				message: res.bledy.join(', '),
			});
			return;
		}

		window.location.replace('/');
	});
};

// co każdą sukundę będziemy wywoływać tą funkcje odejmując 1 sekunde
export const tickSaveTimeout = () => {
	const saveTimeout = Selectors.getSaveTimeout(store.getState());
	if (saveTimeout > 0) {
		store.dispatch(Actions.setSaveTimeout(saveTimeout - 1));
	}
};

export const clearData = () => {
	store.dispatch(Actions.setData(defaultData));
};

export interface Data {
	login: string;
	password: string;
}

export interface Validators {
	login: boolean;
	password: boolean;
}
