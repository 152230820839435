import * as React from "react";
import type { SVGProps } from "react";
const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fillRule="evenodd"
    clipRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    viewBox="0 0 2709.32 2709.32"
    {...props}
  >
    <path
      d="M1366.46 2703.99c733.96 0 1337.55-611.55 1337.55-1349.33 0-730.76-596.37-1349.33-1319.92-1349.33h-94.24C925.21 5.33 583.2 214 398.64 398.55 213.88 583.31 5.31 924.89 5.31 1289.74v82.56c0 742.77 613.59 1331.69 1361.15 1331.69zm662.88-1203.45v498.82h-289.32v-465.68c0-117.08-41.75-196.66-146.4-196.66-80.12 0-127.29 53.98-148.43 105.72-7.55 18.7-9.88 44.83-9.88 70.65v485.87h-289.1s4.03-788.35 0-869.95h289.2v123.35c-.42.96-1.27 1.81-1.7 2.98h1.7v-2.98c38.57-59.28 107.1-143.75 260.73-143.75 190.72 0 333.2 124.31 333.2 391.63zM843.72 709.96c-99.03 0-163.73 64.81-163.73 150.44 0 83.19 62.79 150.34 159.9 150.34h1.81c101.04 0 163.73-67.15 163.73-150.34-2.02-85.63-62.69-150.44-161.71-150.44zM697.2 1999.36h289.21v-870.05H697.2v870.05z"
      className="fil0"
    />
  </svg>
);
export default SvgLinkedin;
