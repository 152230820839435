import React from 'react';
import { loadAppResources } from '.';
import ErrorsComponent from 'errors';
import DialogsComponent from 'dialog';
import ToastsComponent from 'toasts';
import LoginRouting from './pages/LoginRouting';

function App() {
	loadAppResources();
	return (
		<>
			<LoginRouting />
			<ErrorsComponent />
			<ToastsComponent />
			<DialogsComponent />
		</>
	);
}

export default App;
