import { pFetch } from '../../../core/pFetch';
import { KwardaError } from '../../../models/Global';

const endPoints = {
	apiBase: '/api.php',
};

export const sendConfirm = (data: { kod: string }) => {
	const payload = new FormData();
	payload.append('kod', JSON.stringify(data));

	return pFetch<void | KwardaError>(endPoints.apiBase, {
		method: 'POST',
		body: {
			content: payload,
			type: 'formdata',
		},
		customHandlers: {
			onReject: {
				fn: (client, resolve, reject) => {
					if (
						client
							.getResponseHeader('Content-Type')
							?.includes('application/json')
					) {
						resolve(JSON.parse(client.response) as KwardaError);
						return;
					}
					reject({
						status: client.status,
						statusText: client.statusText,
					});
				},
				returnAfter: true,
			},
		},
	});
};
