import { LoginPayload } from '.';
import { pFetch } from '../../../core/pFetch';
import { KwardaLoginError } from '../../../models/Global';

const endPoints = {
	apiBase: '/api.php',
	ping: '/api.php?q',
};

export const login = (data: LoginPayload) => {
	const payload = new FormData();
	payload.append('zaloguj', JSON.stringify(data));

	return pFetch<void | KwardaLoginError>(endPoints.apiBase, {
		method: 'POST',
		body: {
			content: payload,
			type: 'formdata',
		},
		customHandlers: {
			onReject: {
				fn: (client, resolve, reject) => {
					if (
						client
							.getResponseHeader('Content-Type')
							?.includes('application/json')
					) {
						resolve(
							JSON.parse(client.response) as KwardaLoginError
						);
						return;
					}
					reject({
						status: client.status,
						statusText: client.statusText,
					});
				},
				returnAfter: true,
			},
		},
	});
};

export const getHasTwoFactor = () => {
	return pFetch<boolean>(endPoints.ping, {
		customHandlers: {
			onLoad: {
				fn: (client, resolve, reject) => {
					if (client.status === 200) {
						resolve(false);
					}
					if (client.status === 404) {
						resolve(true);
					}
					reject({
						status: client.status,
						statusText: client.statusText,
					});
				},
				returnAfter: true,
			},
		},
	});
};
