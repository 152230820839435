import { store } from '../../../core/Store';
import { Actions } from './reducer';
import * as Selectors from './selectors';

export { Actions, Selectors };

export const initViewportWidthTracker = () => {
	setInterval(() => {
		store.dispatch(Actions.setViewportWidth(window.innerWidth));
	}, 1000);
};
