import { createSlice } from '@reduxjs/toolkit';
import * as Seters from './seters';

export interface State {
	viewportWidth: number;
}

export const initialState: State = {
	viewportWidth: window.innerWidth,
};

export const slice = createSlice({
	name: 'ui/app',
	initialState,
	reducers: Seters,
});

export const appReducer = slice.reducer;
const Actions = slice.actions;
export { Actions };
