import { AppState } from '../../../core/Store';
import { State } from './reducer';

type Selector<T = any> = (redState: State, appState: AppState) => T;
function prepareSelector<T>(selector: Selector<T>) {
	return (state: AppState) => {
		return selector(state.data.app, state);
	};
}

export const getIsDataLoaded = prepareSelector((redState, appState) => {
	return redState.isDataLoaded;
});

export const getTwoFactorEnabled = prepareSelector((redState, appState) => {
	return redState.twoFactorEnabled;
});
