import React, { Component } from 'react';
import styled from 'styled-components';
import { GRAY_100 } from '../../core/colors';

const Wrapper = styled.div`
	border-radius: 10px;
	cursor: text;
	height: 40px;
	border: 2px solid ${GRAY_100};
	margin: 8px 0;
`;

const Input = styled.input`
	all: unset;
	width: 100%;
	height: 100%;
	color: #000;
	padding-left: 10px;
	height: 35px;
`;

const Error = styled.span`
	color: #d12f2f;
	font-size: 12px;
`;

type Props = {
	placeholder: string;
	value: string;
	onChange: (value: string) => void;
	error: boolean;
	type?: React.HTMLInputTypeAttribute;
};

type State = {};

class FormInput extends Component<Props, State> {
	state = {};

	render() {
		return (
			<Wrapper>
				<Input
					value={this.props.value}
					onChange={(e) => this.props.onChange(e.target.value)}
					placeholder={this.props.placeholder}
					type={this.props.type || 'text'}
				/>
				{this.props.error && <Error>niepoprawne dane logowania</Error>}
			</Wrapper>
		);
	}
}

export default FormInput;
