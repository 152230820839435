import styled from 'styled-components';

export const Wrapper = styled.div`
	margin: auto;
	z-index: 9;
	display: flex;
	flex-flow: row nowrap;
	width: 650px;
	@media screen and (max-width: 800px) {
		width: 550px;
	}
	@media screen and (max-width: 700px) {
		width: 500px;
	}
`;

export const LeftSection = styled.div`
	background-color: #fff;
	flex: 70;
	border-radius: 20px 0 0 20px;
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	padding: 25px;
	padding-bottom: 85px;
	position: relative;
	overflow: hidden;
	min-height: 410px;
`;

export const ClientFont = styled.p`
	margin: 0;
	font-weight: 600;
	font-size: 26px;
	line-height: 1.1;
	margin-left: 10px;
`;

export const ActionFont = styled.p`
	margin: 5px 0 25px 10px;
`;

export const LeftFoot = styled.div`
	width: 100%;
	height: 70px;
	position: absolute;
	bottom: 0;
	margin-left: -25px;
	display: flex;
	flex-flow: row nowrap;
	&::before {
		content: '';
		display: block;
		position: absolute;
		bottom: 70px;
		width: 100%;
		height: 30px;
		background: linear-gradient(0deg, #00000014, transparent);
		pointer-events: none;
	}
	p {
		margin: 0;
		line-height: 1.3;
	}
`;

export const FooterLeft = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-start;
	padding-left: 40px;
	a {
		color: #000;
		font-weight: 600;
	}
`;

export const FooterRight = styled.div`
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-end;
	padding-right: 40px;
	span {
		font-weight: 600;
	}
`;

export const RightSection = styled.div`
	background-color: rgb(35 55 108 / 50%);
	flex: 30;
	border-radius: 0 20px 20px 0;
	position: relative;
	padding: 20px;
`;

export const LogoContainer = styled.div`
	margin: 20px auto;
	display: flex;
	justify-content: center;
	svg {
		fill: #fff;
		width: 60px;
		height: 60px;
	}
`;

export const DecorContainer = styled.div`
	margin-top: 100px;
	display: flex;
	justify-content: center;
	svg {
		fill: #fff;
		width: 100px;
		height: 100px;
	}
`;

export const RightFoot = styled.div`
	width: 100%;
	height: 70px;
	position: absolute;
	bottom: 0;
	margin-left: -25px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	background-color: rgba(35, 55, 108, 0.1);
	margin-left: -20px;
	border-radius: 0 0 20px 0;
	svg {
		fill: #5a6277;
		width: 25px;
		height: 25px;
		margin: 0 8px;
	}
`;

export const PinContainer = styled.div`
	width: 300px;
	margin: 20px auto;
	margin-bottom: 40px;
	display: flex;

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
		appearance: textfield;
	}

	input {
		all: unset;
		background-color: #e8e8e8;
		flex: 1;
		width: unset;
		min-width: 0;
		height: 40px;
		margin: 0 7px;
		text-align: center;
		font-size: 18px;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@media screen and (max-width: 410px) {
		width: 270px;
	}
`;

export const ConfirmTopFont = styled.p`
	margin: 0;
	font-weight: 600;
	font-size: 20px;
	margin-top: 50px;
	text-align: center;
`;

export const ConfirmBottomFont = styled.p`
	text-align: center;
	margin: 0;
	margin-bottom: 10px;
	line-height: 1.3;
	margin-top: 8px;
`;
