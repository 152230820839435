import { defaultErrorObject, ErrorManager, BasicTemplateError } from 'errors';

export const throwErrorWithDefaultTemplate = (
	title: string,
	error: any,
	canClose: boolean = true,
	onClose?: () => void
) => {
	const errorObject = Object.assign({}, defaultErrorObject, {
		title,
		content: (
			<BasicTemplateError
				message={error.message}
				code={error.code}
				error_id={error.error_id}
			/>
		),
		canClose,
		onClose,
	});
	return ErrorManager.pushError(errorObject);
};

export const cleanNumberInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
	const filtered = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];

	if (filtered.includes(e.code)) return;

	if (isNaN(parseInt(e.key))) {
		e.preventDefault();
	}
};
