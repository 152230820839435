import React, { Component } from 'react';
import * as Styles from './Styles';
import Panel from './panel/Panel';
import { ConnectedProps, connect } from 'react-redux';
import { AppState } from '../../core/Store';
import * as AppManager from '../../reducers/ui/app';
import Mobile from './mobile/Mobile';

type State = {};

class BaseViewComponent extends Component<PropsFromRedux, State> {
	state = {};

	render() {
		return (
			<Styles.Wrapper>
				<Styles.Container>
					<Styles.VideoBg autoPlay muted loop>
						<source
							src="/video/background_video_1366x768_blur.mp4"
							type="video/mp4"
						/>
					</Styles.VideoBg>
					{this.props.viewportWidth >= 600 ? <Panel /> : <Mobile />}
				</Styles.Container>
			</Styles.Wrapper>
		);
	}
}

interface Own {}

const connector = connect(
	(state: AppState, own: Own) => ({
		viewportWidth: AppManager.Selectors.getViewportWidth(state),
	}),
	(dispatch) => ({})
);

export type PropsFromRedux = ConnectedProps<typeof connector> & Own;

const BaseView = connector(BaseViewComponent);
export default BaseView;
